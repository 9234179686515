import { Injectable } from "@angular/core";
import { CustomFormValidators } from "../validators/custom-form-validators";

@Injectable()
export class EmailValidatorService {
  public validateEmails(emails: string[]): boolean {
    if (emails?.length >= 0) {
      return emails.every((mail) =>
        CustomFormValidators.EMAIL_REGEX.test(mail),
      );
    }

    return false;
  }

  public validateEmail(mail: string): boolean {
    return CustomFormValidators.EMAIL_REGEX.test(mail);
  }
}
